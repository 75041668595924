export default {
  data () {
    return {
      permissions: null,
      role: null,
      CONSTANTS: {
        equipments: 'equipment',
        custommodule: 'custommodule',
        projectdeviation: 'projectdeviation',
        projectequipment: 'projectequipment',
        LIST: 'list',
        CREATE: 'create',
        EDIT: 'edit',
        DELETE: 'delete',
        warehouse: 'warehouse'
      }
    }
  },
  created () {
    this.permissions = this.$formatter.cloneVariable(this.$store.state.auth.permissions)
    this.role = this.$formatter.cloneVariable(this.$store.state.auth.role)
  },
  methods: {
    isAdmin () {
      return ['admin', 'superadmin'].includes(this.role.slug)
    },
    modulePermissions (module) {
      return this.permissions[module]
    },
    hasPermissionObj (module) {
      return Boolean(this.permissions && this.permissions[module])
    },
    checkActionPermission (module, action) { // moduleName and action
      let hasPermission = false
      if ((this.permissions && this.permissions[module])) {
        switch (action) {
          case 'list':
            hasPermission = (this.isAdmin() || this.permissions[module].list)
            break
          case 'create':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].create))
            break
          case 'edit':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].edit))
            break
          case 'delete':
            hasPermission = (this.isAdmin() || (this.permissions[module].list && this.permissions[module].delete))
            break
          default:
            break
        }
      } else hasPermission = true
      return hasPermission
    }
  }
}
