<template>
  <div class="mt-2">
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <template v-else>
      <h4 v-if="customModuleObj.projectId" class="pa-1 pl-2">{{$t('message.projects.project')}}: {{printProjectName}}</h4>
      <form-data :references.sync="formReferences" :model="modelObj" ref="customModuleReference">
      <template v-if="modelObj.id && listOfDocuments.length > 0" slot="custom_modules_documents">
        <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
      </template>
    </form-data>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [PermissionsMixin, projectMixin],
  data () {
    return {
      modelObj: {
        id: parseInt(this.$route.params.record_id) || null
      },
      loading: false,
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      fieldsLoading: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? parseInt(this.$cookie.get(process.env.VUE_APP_HOST_USER)) : 0,
      backUrl: '',
      customModuleObj: {
        moduleId: parseInt(this.$route.params.module_id),
        projectId: parseInt(this.$route.params.project_id),
        moduleName: this.$route.query && this.$route.query.name ? this.$route.query.name : ''
      },
      listOfDocuments: [],
      deleteDocumentIds: [],
      hideDetails: true
    }
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings']),
    formReferences () {
      let title = this.modelObj.id ? this.$t('message.common.update') : this.$t('message.customModule.add')
      title += ` ${this.customModuleObj.moduleName}`
      return {
        cardFlat: false,
        cardTitleClass: 'pa-1',
        layoutClass: 'pa-1',
        title, // this.modelObj.id ? `${this.$t('message.common.update')} ${this.customModuleObj.moduleName}` : `${this.$t('message.customModule.add')}`,
        properties: [{
          model: 'files',
          type: this.formType.FILES,
          multiple: true,
          rules: this.$_maxFileSizeValidation(20, this.modelObj.files), // [], // this.$_multiSelectValidation
          label: this.$t('message.customModule.uploadDocument'),
          class: 'xs12 sm4 md3',
          slot: 'custom_modules_documents',
          hideDetails: this.hideDetails
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm4 md3 lg3',
          rows: 3
        }, {
          model: 'is_completed',
          rules: [],
          type: this.formType.CHECKBOX,
          class: 'xs12 sm6 md4 lg3 pt-2',
          label: this.$t('message.customModule.isCompleted')
        }],
        buttons: [{
          name: 'save_project_custom_module',
          color: 'success',
          label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    }
  },
  mounted () {
    if (this.$store.state.common.isDeviationExcluded) this.$router.push('/')
    else {
      if (!this.hostRef.includes(this.$store.state.common.host)) {
        this.hostRef += this.$store.state.common.host
      }
      /* new crud codes adding */
      if (this.modelObj.id) {
        this.getSingleRecord()
      }
      // set back url
      this.backUrl = `/projects/${this.customModuleObj.projectId}/custom_modules/${this.customModuleObj.moduleId}?name=${this.customModuleObj.moduleName}`
      // if has this.customModuleObj.projectId // get the project obj
      if (this.customModuleObj.projectId) this.getProject(this.customModuleObj.projectId)
    }
  },
  components: {
    'form-data': () => import('@/components/FormTemplate'),
    documents: () => import('@/components/Documents/Documents')
  },
  methods: {
    saveUpdateHandler () {
      if (this.$refs.customModuleReference.$refs.validateForm.validate()) {
        this.loading = true
        const files = this.modelObj.files
        const model = this.$formatter.cloneVariable(this.modelObj)
        model.user_id = this.hostUserId
        model.project_id = this.customModuleObj.projectId
        model.moduleId = this.customModuleObj.moduleId
        model.is_completed = model.is_completed ? 1 : 0
        const formData = new FormData()
        if (files) {
          files.forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        const newobj = this.$formatter.removeInvalidFromObject(model)
        setTimeout(() => {
          for (const key in newobj) {
            if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
            // if (key !== 'files') formData.append(key, newobj[key])
          }
          const header = {
            'Content-Type': 'multipart/form-data',
            language: this.$i18n.locale
          }
          // const method = model.id ? 'put' : 'post'
          const url = model.id ? `https://${this.hostRef}/api/custom-modules/${model.id}/update` : `https://${this.hostRef}/api/custom-modules`
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          /* on update check if there is documents in array to delete */
          const ids = [...this.deleteDocumentIds]
          if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
          hostAppApi.post(url, formData, { headers: header })
            .then(response => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              this.$router.go(-1)
              // this.$router.push(this.backUrl)
            })
            .catch((error) => {
              if (error.response && error.response.data && error.response.data.message) {
                // const errorList = this.$formatter.cloneVariable(error.response.data.errors.document)
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', isException: true, text: error.response.data.message || error })
              } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: error })
            })
            .finally(() => {
              this.loading = false
            })
        }, 50)
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    getSingleRecord () {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/custom-modules/${this.modelObj.id}/edit?moduleId=${this.customModuleObj.moduleId}&project_id=${this.customModuleObj.projectId}&user_id=${this.hostUserId}`)
        .then((response) => {
          if (response.data) {
            this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(response.data.documents)
            const newObj = this.$formatter.removeInvalidFromObject(response.data)
            newObj.is_completed = newObj.is_completed === 1
            this.modelObj = newObj
          }
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    }
  },
  watch: {
    isShowDocumentErrors (val) {
      this.hideDetails = !val
    }
  }
}
</script>

<style>

</style>
